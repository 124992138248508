import React from 'react';
import styled from '@emotion/styled';
import { Box, Container, Flex } from '@qga/roo-ui/components';
import Divider from '@/v2/components/ui/Divider';
import Link from '@/v2/components/ui/Link';
import Logo from '../Logo';
import GoToTop from './components/GoToTop';
import BuildInfo, { BuildInfoProps } from '@/libs/v2/components/BuildInfo';
import { BUILDKITE_INFO } from '@/config';

const StyledLink = styled(Link.Text)`
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

const LogoLink = styled(Link.Text)`
  line-height: 0;
`;

interface SiteFooterProps {
  withLogo?: boolean;
  hasOffset?: boolean;
}

const SiteFooter = ({ withLogo, hasOffset }: SiteFooterProps) => (
  <Box
    bg="greys.charcoal"
    color="white"
    data-testid="SITE_FOOTER"
    mx="auto"
    width="100%"
    mb={hasOffset ? ['8.5rem', null, '30'] : '0'}
  >
    {withLogo && (
      <Box borderTop="4px solid" borderColor="brand.primary">
        <Container>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <LogoLink href="/">
              <Logo variant="light" />
            </LogoLink>
            <GoToTop />
          </Flex>
        </Container>
      </Box>
    )}
    <Container py="8">
      <Divider />
      <Flex
        flexDirection={['column', null, 'row']}
        justifyContent="space-between"
        alignItems={['start', null, 'center']}
        fontSize="sm"
      >
        <Flex my="6" flexDirection={['column', 'row']}>
          <StyledLink mr="6" mb={[2, 0]} variant="light" href="/contact-us">
            Contact Us
          </StyledLink>
          <StyledLink mr="6" mb={[2, 0]} variant="light" href="/faqs">
            FAQs
          </StyledLink>
          <StyledLink
            mr="6"
            mb={[2, 0]}
            external
            variant="light"
            href="https://www.qantas.com/au/en/support/privacy-and-security.html"
          >
            Privacy &amp; Security
          </StyledLink>
          <StyledLink
            external
            variant="light"
            href="https://www.qantas.com/au/en/support/terms-of-use.html"
          >
            Terms of Use
          </StyledLink>
        </Flex>
        <Flex alignItems="center">
          <Box fontSize="xs" textAlign={['left', null, 'right']}>
            <Box>© Qantas Airways Limited ABN 16 009 661 901</Box>
          </Box>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        fontSize="sm"
      >
        <Box mt={['6', null, '0']}>
          We acknowledge the Aboriginal and Torres Strait Islander Traditional
          Custodians of the land on which we work, live and fly.
          <br />
          We pay respect to Elders past, present and emerging.
        </Box>
      </Flex>
      <BuildInfo
        brand="qantas"
        info={BUILDKITE_INFO as BuildInfoProps['info']}
      />
    </Container>
  </Box>
);

export default SiteFooter;
